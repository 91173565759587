import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 82",
  week: "Semana 12",
  day: "03",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-03",
  path: "/cronologia/semana-12#dia-03-jun/",
};

const Day82 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModCovidHighlight>
          La Operación Balmis contra COVID-19 rememora al hombre que lideró la
          primera misión humanitaria de la historia.
        </ModCovidHighlight>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.defensa.gob.es/Galerias/gabinete/red/2020/05/p-54-57-red-372-historia-balmis.pdf"
            name="Historia de Balmis"
          />
        </ModReferenceList>
      </ContentLeft>

      <ContentRight>
        <ModDailyHighlight>
          Octavo día del periodo de luto oficial
        </ModDailyHighlight>
        <ModText>
          En los últimos siete días se han diagnosticado 2,376 casos mediante
          pruebas PCR. En ese mismo periodo, 221 personas han requerido
          hospitalización (9,3% de los diagnósticos), de los que 10 han sido
          ingresos en la UCI.
        </ModText>
        <ModText>
          El Presidente del Gobierno ha solicitado una{" "}
          <strong>última prórroga</strong> del estado de alarma,{" "}
          <strong>hasta el próximo 21 de junio</strong>, para gestionar la
          crisis de COVID-19. En esta nueva prórroga, el Ministerio de Sanidad
          continuará adoptando las medidas previstas en el marco del estado de
          alarma y el Plan de desescalada, para aquellos territorios que se
          encuentren en Fase 1 o 2.
        </ModText>
        <ModImage
          src="/images/svg/presidente-atril.svg"
          alt="Presidente atril"
        />

        <ModText>
          En los terriorios que se encuentren o pasen a la Fase 3, será el
          Presidente de la CC.AA. quien tenga la facultad de decidir si
          mantienen o modifican las medidas contenidas en la Orden de Fase 3.
          Además, también será la Comunidad Autónoma quien dicte la superación
          de esta fase, y por tanto, el paso a la Nueva Normalidad.
        </ModText>
        <ModText>
          Para tener un mayor control del virus, el Grupo de Análisis Científico
          del Coronavirus, creado por el ISCII, ha realizado un{" "}
          <InlineLink link="https://www.conprueba.es/sites/default/files/informes/2020-06/DETECCI%C3%93N%20DE%20SARS-CoV-2%20EN%20AGUDAS%20RESIDUALES%20COMO%20HERRAMIENTA%20DE%20VIGILANCIA%20Y%20ALERTA%20R%C3%81PIDA_1.pdf">
            análisis de las aguas residuales
          </InlineLink>
          , ya que supone un indicador de circulación de COVID-19 entre la
          población.
        </ModText>
        <ModText>
          Aunque los síntomas respiratorios son los que se describen más
          frecuentemente en pacientes con COVID-19, varios estudios han sugerido
          que el tracto gastrointestinal podría verse afectado por el virus, y
          que el material genético de éste, está presente en heces de pacientes
          con la enfermedad.
        </ModText>
        <ModText>
          La capacidad que tiene la vigilancia de las aguas residuales para
          detectar casos leves o asintomáticos es una de sus principales
          ventajas, y puede ser una herramienta de alerta temprana para
          identificar de forma precoz la presencia del SARS-CoV-2, tanto en el
          momento actual como en posibles rebrotes o segundas oleadas de la
          infección.
        </ModText>
        <ModText>
          El Ministerio de Transportes, Movilidad y Agenda Urbana (Mitma) por su
          parte, ha puesto en marcha un{" "}
          <InlineLink link="https://www.mitma.es/ministerio/covid-19/evolucion-movilidad-big-data/indicadores_movilidad_desescalada">
            nuevo punto de acceso de información sobre movilidad
          </InlineLink>{" "}
          para los ciudadanos.
        </ModText>
        <ModImage
          src="/images/svg/1004-ia-computer.svg"
          alt="estudio bigData covid-19"
        />

        <ModText>
          Esta herramienta, junto con el{" "}
          <InlineLink link="https://www.mitma.es/ministerio/covid-19/evolucion-movilidad-big-data">
            estudio de movilidad en España con Big Data
          </InlineLink>
          , refleja el aumento de movilidad que se está produciendo en el
          proceso de desescalada, especialmente en el ámbito intraprovincial.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day82;
